import { Flex, Grid } from "@chakra-ui/react";

import Footer from "../components/Footer";
import Header from "../components/Header";
import Mosaicoitem from "../components/MosaicoItem";

import Reel2021 from "../img/Reel2021.jpg"
import PagBank from "../img/Pagbank.jpg"
import RecordSSpt1 from "../img/RecordSSpt1.jpg"
import Reel2020 from "../img/Reel2020.jpg"
import FunStation from "../img/FunStation.jpg"
import RecordSSpt2 from "../img/RecordSSpt2.jpg"
import Mosca from "../img/Mosca.jpg"
import GreenFellas from "../img/GreenFellas.jpg"
import Reel2019 from "../img/Reel2019.jpg"
import GoodluckBro from "../img/GoodluckBro.jpg"
import PowerGameplay from "../img/PowerGameplay.jpg"
import TinyTown from "../img/TinyTown.jpg"
import { useEffect } from "react";

export default function Home() {
    useEffect(() => {
      localStorage.clear()
    }, [])
    
    return(
        <Flex bg="white" flexDirection="column">
            <Header/>
                <Grid templateColumns='repeat(2, 1fr)' gap={0} mb="1rem" h="100vh" maxH="100%">
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/717297482?h=c5e282eb67" imgTitle="Motion Reel 2021" imgSrc={Reel2021}/>
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/716801692?h=7847a27334" imgTitle="Pagbank" imgSubTitle="LemonadeTV Motion" imgSrc={PagBank}/>
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/716798244?h=87b7507e33" imgTitle="Histórias de Sansão" imgSubTitle="TV Record Motion and Art Direction" imgSrc={RecordSSpt1}/>
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/511320708?h=2b1a414572" imgTitle="Motion Reel 2020" imgSrc={Reel2020}/>
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/737149919?h=c0879c6cd4" imgTitle="Fun Station" imgSubTitle="Short Film 3D" imgSrc={FunStation}/>
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/717296579?h=64a6d3cdbd" imgTitle="Histórias de Sansão pt.2" imgSubTitle="TV Record Motion and Art Direction" imgSrc={RecordSSpt2}/>
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/716782618?h=1a6ba50ad6" imgTitle="Mosca" imgSubTitle="Teaser Motion and Art Direction" imgSrc={Mosca}/>
                    <Mosaicoitem hasClick="false" videoSrc="" imgTitle="Greenfellas Illustration" imgSubTitle="Poster" imgSrc={GreenFellas}/>
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/737150124?h=f44bcea2e6" imgTitle="Motion Reel 2019" imgSrc={Reel2019}/>
                    <Mosaicoitem hasClick="false" videoSrc="" imgTitle="Good luck Bro. Illustration" imgSubTitle="Poster" imgSrc={GoodluckBro}/>
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/737150230?h=6cd1a95603" imgTitle="Power Gameplays" imgSubTitle="Short Film 3D" imgSrc={PowerGameplay}/>
                    <Mosaicoitem videoSrc="https://player.vimeo.com/video/737150409?h=9b89422a17" imgTitle="Tiny Town" imgSubTitle="Short Film 3D" imgSrc={TinyTown}/>
                </Grid>
            <Footer/>
        </Flex>
    )
}