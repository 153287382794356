import { Flex, Text, useBreakpointValue } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Lottie from 'lottie-react';
import animationData from '../props/HEAD_HEY-DIO-WTF.lottie.json';
import MobileDrawer from "./MobileDrawer";

export default function Header() {
    const isWideVersion = useBreakpointValue({
        base: false,
        lg: true,
    });

    const navigate = useNavigate();
    const handleGoTo = (event:any, path:any) => {
        event.preventDefault();
        navigate({ pathname: `${path}` });
    }

    return(
        <Flex 
                bg="black" 
                w="100%" h="4rem" 
                color="white" 
                alignItems="center" 
                justifyContent="space-between"
                px="5rem"
            >
                <Flex onClick={() => handleGoTo} cursor="pointer" w="4rem" h="4rem">
                    <Lottie loop={true} animationData={animationData}/>
                </Flex>

                {isWideVersion ?
                    <Flex justifyContent="space-evenly" w="40%">
                        <Text px="0.2rem" cursor="pointer" _hover={{background: "#63ffc7", color: "#000"}} onClick={(e) => {handleGoTo(e,"/")}}>GALLERY</Text>
                        <Text px="0.2rem" cursor="pointer" _hover={{background: "#63ffc7", color: "#000"}} onClick={(e) => {handleGoTo(e,"/video")}}>REEL</Text>
                        <Text px="0.2rem" cursor="pointer" _hover={{background: "#63ffc7", color: "#000"}} onClick={(e) => {handleGoTo(e,"/contact")}}>CONTACT</Text>
                        <Text px="0.2rem" cursor="pointer" _hover={{background: "#63ffc7", color: "#000"}} onClick={(e) => {handleGoTo(e,"/about")}}>ABOUT</Text>
                    </Flex>
                :
                    <MobileDrawer/>
                }
            </Flex>
    )
}