import { IconButton, Menu, MenuButton, MenuItem, MenuList} from "@chakra-ui/react";
import {  FiMenu  } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

export default function MobileDrawer() {

    const navigate = useNavigate();

    const handleGoTo = (event:any, path:any) => {
        event.preventDefault();
        navigate({ pathname: `${path}` });
    }

    return(
        <Menu>
            <MenuButton
                as={IconButton}
                aria-label='Menu'
                icon={<FiMenu />}
                _hover={{color: "black", background: "white"}}
                color="white"
                bg="black"
            />
            <MenuList color="black">
                <MenuItem onClick={(e) => {handleGoTo(e,"/video")}}>
                    REEL
                </MenuItem>
                <MenuItem onClick={(e) => {handleGoTo(e,"/")}}>
                    GALLERY
                </MenuItem>
                <MenuItem onClick={(e) => {handleGoTo(e,"/contact")}}>
                    CONTACT
                </MenuItem>
                <MenuItem onClick={(e) => {handleGoTo(e,"/about")}}>
                    ABOUT
                </MenuItem>
            </MenuList>
        </Menu>
    )
}