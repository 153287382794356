import { Flex, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import Lottie from "lottie-react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import animationData from '../props/[ABOUT]DIO.lottie.json';

export default function About() {
    const yearMade = 2022;
    const [workingYears, setWorkingYears] = useState(yearMade);
    
    const countYears = () => {
        let currentDate = new Date();
        let year = currentDate.getFullYear();
        setWorkingYears((year - yearMade) + 4);
    };
    useEffect(() => {
        countYears();
    }, [])

  return (
    <Flex bg="#f9f9f9" flexDirection="column" minH="100vh">
        <Header/>
        <Flex flexDirection="column" alignItems="center" h="100%">
            <Flex w="14rem" h="14rem" mt="2rem" mb="1rem">
                <Lottie loop={true} animationData={animationData}/>
            </Flex>
            <Flex flexDirection="column" alignItems="center" maxW="80%">
                <Heading>
                    Hi!
                </Heading>
                <Text textAlign="center" fontSize="xl" fontWeight="normal" color="black" maxW="60rem">
                    <br/>
                    I'm a Freelance Motion Designer & Art Director based in São Paulo, Brazil. <br/>
                    Over these {workingYears} years working with Motion Design, I had a chance to do fun projects for agencies, producers, studios, and TV Shows. <br/>
                    <br/>
                    I'm always looking for new challenges and cool stuff, so if you are interested please let me know how can I help You :)
                </Text>
            </Flex>
        </Flex>
        <Footer/>
    </Flex>
  )
}
