import { Flex, Link, Text } from "@chakra-ui/react";
import Marquee from "react-fast-marquee";

export default function Footer() {
    let year = new Date().getFullYear();

    return(
        <footer>
            <Flex position="fixed" bottom="0">
                <Marquee pauseOnHover gradient={false} speed={120} style={{background: "#63ffc7"}}>
                    <Link mx="2rem" target="_blank" href="https://vimeo.com/diolucas">VIMEO</Link>
                    <Link mx="2rem" target="_blank" href="https://www.instagram.com/dio.wtf/">INSTAGRAM</Link>
                    <Link mx="2rem" target="_blank" href="https://dribbble.com/LucasAlva">DRIBBBLE</Link>
                    <Link mx="2rem" target="_blank" href="https://www.behance.net/dio_lucas">BEHANCE</Link>
                    <Link mx="2rem" target="_blank" href="https://www.linkedin.com/in/lucas-alves-252575185/">LINKEDIN</Link>
                    <Link mx="2rem" target="_blank" href="mailto:hello@heydio.wtf?body=Hey Dio! ">EMAIL</Link>
                    <Text mx="2rem">#HEYDIO</Text>
                    <Text mx="2rem">©{year} DIO. ALL RIGHTS RESERVED</Text>
                </Marquee>
            </Flex>
        </footer>
    )
}