import {
  ChakraProvider,
} from "@chakra-ui/react"
import { BrowserRouter } from "react-router-dom"
import theme from "../theme"
import { AppRoutes } from "./Routes"

export const App = () => (
    <ChakraProvider theme={theme}>
      <BrowserRouter basename="/">
        <AppRoutes/>
      </BrowserRouter>
    </ChakraProvider>

)
