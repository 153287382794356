import { Route, Routes } from "react-router-dom";
import LoadingScreen from "../components/LoadingScreen";
import About from "./About";
import Contact from "./Contact";
import Home from "./Home";
import Video from "./Video";

export function AppRoutes() {
    return(
        <Routes>
            <Route path="/" element={<Home/>}/>
            <Route path="/loading" element={<LoadingScreen/>}/>
            <Route path="/about" element={<About/>}/>
            <Route path="/contact" element={<Contact/>}/>
            <Route path="/video" element={<Video/>}/>
            <Route path="*" element={<p>Rota inexistente</p>}/>
        </Routes>
    )
}