import { Flex } from "@chakra-ui/react";
import Lottie from 'lottie-react';
import animationData from '../props/[LOADING].lottie.json';

export default function LoadingScreen() {
    return(
        <Flex w="100vw" h="100vh" backgroundColor="#000" justifyContent="center" alignItems="center">
          <Flex w="30rem" h="30rem">
            <Lottie loop={true} animationData={animationData} width={300} height={300}/>
          </Flex>
        </Flex>
    )
}