import { Flex, Heading, Text } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import Footer from '../components/Footer'
import Header from '../components/Header'
import LoadingScreen from '../components/LoadingScreen';

const Video = () => {
    const [videoTitle, setVideoTitle] = useState<any>("");
    const [videoSrc, setVideoSrc] = useState<any>("");
    useEffect(() => {
        setTimeout(() => {
            if (localStorage.getItem("src")?.toString()) {
                setVideoTitle(localStorage.getItem("title")?.toString());
                setVideoSrc(localStorage.getItem("src")?.toString());
            }else{
                setVideoTitle("Motion Reel 2021");
                setVideoSrc("https://player.vimeo.com/video/717297482?h=c5e282eb67");
            }
            
        }, 1000);
    // eslint-disable-next-line
    }, [videoSrc.length < 1])
    
  return (
    <>
    {videoSrc.length > 0 ?
    <Flex bg="blackAlpha.900" flexDirection="column" minH="100vh">
        <Header/>
        <Flex flexDirection="column" alignItems="center" h="100%">
            <Flex w="40rem" h="20rem" mt="2rem" mb="0.5rem">
                <iframe 
                    title="Animação" 
                    src={videoSrc} 
                    width="720" height="300"
                />
            </Flex>
            <Flex flexDirection="column" alignItems="center" maxW="80%">
                <Heading as="h1" mb="0.5rem" color="white" fontWeight="400">
                    {videoTitle}
                </Heading>
                <Text textAlign="center" fontSize="xl" fontWeight="normal" color="white" maxW="60rem">
                    <Text color="#63ffc7">Credits</Text>
                    Directing: Lucas Dionizio
                    <br/>
                    Design + Animation: Lucas Dionizio
                </Text>
            </Flex>
        </Flex>
        <Footer/>
    </Flex> : 
    <LoadingScreen/>}
    </>
  )
}

export default Video