import { Flex, Heading, Image, Link, Text, useBreakpointValue } from '@chakra-ui/react'
import Lottie from 'lottie-react';
import Footer from '../components/Footer'
import Header from '../components/Header'

import animationData from '../props/[CONTATO]FANTASMA.lottie.json';
import imgMoreMedias from "../props/[CONTATO]more_medias.png"

export default function Contact() {
    const isWideVersion = useBreakpointValue({
        base: false,
        lg: true,
    });

  return (
    <Flex bg="#f9f9f9" flexDirection="column" minH="100vh">
        <Header/>
        <Flex flexDirection="column" alignItems="center" h="100%">
            <Flex w="auto" mt="3rem">
                <Heading as="h4" size="lg" textAlign="center" mr="0.2rem" fontWeight="400">
                    it's a kind of appetite for
                </Heading>
                <Heading as="h4" size="lg" textAlign="center" bg="#63ffc7" fontWeight="400">&nbsp;anymotion&nbsp;</Heading>
            </Flex>
            <Flex w="18rem" h="18rem">
                <Lottie loop={true} animationData={animationData} width={230} height={230} style={{marginTop: "2rem", marginBottom: "1rem"}}/>
            </Flex>
            <Flex flexDirection="column" alignItems="center" maxW="80%">
                <Heading as="h4" size="xl" textAlign="center" fontWeight="600" mb="1rem">
                    Contact
                </Heading>
                <Text textAlign="center" fontSize="xl" fontWeight="normal" color="black">
                    Lucas Dionizio
                    <br/>
                    Motion Designer & Art Director
                </Text>
                <Link fontSize="xl" fontWeight="bold" mx="2rem" target="_blank" href="mailto:hello@heydio.wtf?body=Hey Dio! " color="#63ffc7">hello@dio.wtf</Link>
            </Flex>
            {isWideVersion ? 
            <Image
                boxSize="100%"
                objectFit='fill'
                src={imgMoreMedias}
                alt="More medias below"
                w="15rem"
                h="15rem"
                position="absolute"
                bottom="0vh"
                right="15vw"
            /> :
            <></>}
            
        </Flex>
        <Footer/>
    </Flex>
  )
}
