import { GridItem, Flex, Image, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

interface MosaicoItemProps {
    imgSrc: string;
    imgTitle: string;
    imgSubTitle?: string;
    videoSrc: string;
    hasClick?: string;
}

const Mosaicoitem = ({ imgSrc, imgSubTitle, imgTitle, videoSrc, hasClick }: MosaicoItemProps) => {
    const [isHovering, setIsHovering] = useState(false);
    const handleMouseOver = () => {
        setIsHovering(true);
    };

    const handleMouseOut = () => {
        setIsHovering(false);
    };
    const navigate = useNavigate();
    const handleRedirectToVideo = (event:any, src:string, title:string) => {
        localStorage.setItem("title", title);
        localStorage.setItem("src", src);
        navigate({ pathname: `video` });
    }

    return(
        <GridItem w='100%' h={{base: "auto", xl: "50vh"}} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} cursor="pointer" onClick={(e) =>{!!!hasClick ? handleRedirectToVideo(e, videoSrc, imgTitle) : console.log()}}>
            <Flex justifyContent="center" alignItems="center" boxSize="100%" position="relative">
                <Image
                    boxSize="100%"
                    objectFit='fill'
                    src={imgSrc}
                    alt={imgTitle}
                    filter={isHovering ? "auto": "none"}
                    blur={isHovering ? "2px": "none"}
                />
                {isHovering && 
                    <Flex w='100%' h="100%" position="absolute" bg="rgba(0,0,0, 0.4)" justifyContent="center" alignItems="center">
                        <Flex border="2px solid #F9F9F9" w='80%' h='60%' color="#F9F9F9" flexDirection="column" justifyContent="center" fontWeight="600" fontSize={["xs", "sm", "lg"]}>
                            <Text alignSelf="flex-start" w="auto" pl="3rem">{imgTitle}</Text>
                            <br/>
                            <Text alignSelf="flex-start" w="auto" pl="3rem">{imgSubTitle}</Text>
                        </Flex>
                    </Flex>
                }
                
            </Flex>
        </GridItem>
    )
}

export default Mosaicoitem;